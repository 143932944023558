import React from 'react'

//Create a header component
const Header = () => {
  return (
    <>
        <h1>Hangman</h1>
        <p>Find the hidden word - Enter a letter</p>
    </>
  )
}

export default Header